<template>
  <form @submit.prevent="submit" id="create-counterparty">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            v-model="counter_party.name"
            class="form-control"
            required
            placeholder="Name"
            name="name"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Category</label>
          <select
            v-model="counter_party.category"
            class="form-control"
            required
            name="category"
          >
            <option value="counties">Counties</option
            ><option value="ministries">Ministries</option
            ><option value="parastatals">Parastatals</option
            ><option value="private_company">Private Company</option
            ><option value="ngo">NGO</option
            ><option value="universities">Universities</option
            ><option value="banks">Banks</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Physical Address</label>
          <input
            name="physical_address"
            type="text"
            v-model="counter_party.physical_address"
            class="form-control"
            required
            placeholder="Physical Address"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Postal Address</label>
          <input
            name="postal_address"
            type="text"
            v-model="counter_party.postal_address"
            class="form-control"
            required
            placeholder="Postal Address"
          />
        </div>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-secondary">
          <i class="lni-save mr-2"></i> Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      counter_party: {},
    };
  },

  methods: {
    submit() {
      this.$loader.start()
      this.$axios
        .post("/api/v1/dashboard/counterparties", this.counter_party)
        .then(() => {
          this.$loader.stop()
          this.$emit('close')
          this.$emit('added')
        })
        .catch((error) => {
          this.$loader.stop()
          this.$http(error.response, "create-counterparty");
        });
    },
  },
};
</script>
